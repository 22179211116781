<template>
  <div id="mainpricacy" class="mt-30">
    <div class="top__header">
      개인정보처리방침
    </div>
    <div class="content-list pc-only">
      <div class="top__content-box">
        남해군에서 운영하는 온라인 관광플랫폼(이하 “낭만남해“라 한다)은 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보처리방침을 수립·공개합니다.
      </div>
      <div class="flex">
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제1조 (개인정보 처리목적)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'first'">
            낭만남해는 다음의 목적을 위하여 개인정보를 처리합니다.<br>
            ① 홈페이지 회원가입 및 관리<br>
            웹사이트에서 제공하는 서비스 이용을 위한 목적, 본인확인, 개인식별, 가입의사 확인, 연력 확인, 민원 처리, 고지사항 전달 등<br>
            ② 서비스 제공<br>
            예약결제 등 낭만남해 서비스 제공을 위한 업무 전반에 필요한 정보 제공 등<br>
            ③ 통계 활용<br>
            서비스의 유효성 확인, 접속빈도 파악 또는 서비스 이용에 대한 통계 등
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('second')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제2조(개인정보 수집이용 항목 및 보유기간)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'second' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'second'">
            ① 낭만남해가가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며 수집하는 개인정보의 이용 목적, 항목, 보유기간은 다음과 같습니다.<br><br>
            수집이용목적(회원가입, 계약의 이행 및 서비스 제공, 예약∙구매∙관심상품 내역, 결제대금의 청구, 상담∙불만∙민원처리, 고지∙안내사항 전달, 상품∙서비스 이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천, 불법∙부정이용방지)<br>
            수집이용항목(
            [일반 회원]
            휴대전화번호(ID), 비밀번호, SNS 계정 정보(네이버, 카카오, 애플로 가입 시), 본인확인정보(성명, 휴대전화번호, 통신사, 생년월일, 성별, 내외국인여부) 등<br><br>

            [입점업체]<br>
            아이디, 비밀번호, 이메일, 대표자명, 연락처, 계좌정보, 사업자등록번호, 사업장 주소 등<br>

            [서비스 이용 및 부정이용 기록 확인]<br>
            서비스 이용시간/이용기록, 접속로그, 이용컨텐츠, 접속IP정보, 기기모델명, 브라우저 정보, PC/모바일 환경)<br>

            보유 및 이용기간(- 회원 탈퇴 시 상담 등 민원처리, 정산 및 환불 처리를 위해 30일 후 삭제<br>
            - 관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관 (예: 구매 회원의 경우 5년간, IP의 경우 3개월))<br><br>
            수집이용목적(예약/결제,예약이력 확인)<br>
            수집이용 항목(예약정보(예약자 이름, 휴대전화번호, 이메일), 결제정보 * 결제 시 개인정보는 PG(결제대행업체)사에서 수집 및 저장하고 있으며, 낭만남해는 PG사에서 제공하는 거래 내역만 제공받음)<br>
            보유 및 이용기간(전자상거래 등에서의 소비자 보호에 관한 법률에 따라 5년 보관)<br><br>
            수집이용목적(위치기반서비스 제공)<br>
            수집이용 항목(이용자 위치를 활용한 서비스 제공)<br>
            보유 및 이용기간(일회적 이용 후 즉시 삭제)<br><br>
            수집이용목적(물품(경품)배송)<br>
            수집이용 항목(이름, 주소, 휴대전화번호, 신분증 사본(제세공과금 처리 시))<br>
            보유 및 이용기간(물품(경품) 배송 완료 시까지)<br><br>
            ② 수집방법
            이용자들에 대한 서비스 제공을 위한 필수적인 정보들을 온라인 상에서 입력 받고 있습니다. 그러나, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시 구할 것입니다.<br><br>
            ③ 다음의 경우는 예외적으로 법령에 따라 동의없이 개인정보를 수집·이용할 수 있습니다. <br>
              - 정보통신서비스의 제공에 관한 계약을 이행을 위하여 필요한 개인정보로서 경제적·기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우<br>
              - 정보통신서비스의 제공에 따른 비용 또는 요금 정산을 위하여 필요한 경우<br>
              - 수집목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않는 경우<br>
              - 그 밖에 법률에 특별한 규정이 있는 경우<br><br>
            ④ 관련법령에 의한 정보보유 사유 및 의무<br>
            계약 또는 청약철회 등에 관한 기록(5년) 전자상거래등에서의 소비자보호에 관한 법률<br>
            대금결제 및 재화 등의 공급에 관한 기록(5년) 전자상거래 등에서의 소비자보호에 관한 법률<br>
            소비자의 불만 또는 분쟁처리에 관한 기록(5년) 전자상거래 등에서의 소비자보호에 관한 법률<br>
            웹사이트 방문 기록(3개월) 통신비밀보호법<br>
            전자금융거래에 관한 기록(5년) 전자금융거래법<br>
            고객확인 및 의심거래보고 등에 관한 기록(5년) 특정 금융거래정보의 보고 및 이용 등에 관한 법률<br><br>
            ⑤ 개인정보 유효기간제(휴면 계정 정책)<br>
              1) 1년간 서비스 이용 기록이 없는 정보주체(이용자)의 회원가입 정보는 개인정보 보호법 제39조 6에 따라 일반정보 주체(이용자)의 회원가입 정보와 분리하여 별도로 저장 및 관리됩니다. (휴면 계정으로 전환)<br>
              2) 다른 법령에서 별도의 기간을 정하고 있거나 정보 주체(이용자)의 요청이 있는 경우를 제외하면, 법령에서 정의하는 기간 동안 재이용하지 아니하는 회원의 개인정보를 파기하거나 다른 회원의 개인정보와 분리하여 별도로 저장·관리합니다. <br>
              3) 기간 만료 30일 전 개인정보가 파기되거나 분리되어 저장·관리되는 사실을 문자, 이메일 또는 이와 유사한 방법으로 회원에게 알립니다. <br>
              4) 휴면 계정으로 전환된 후 5년이 지나도 서비스를 이용하지 않으면 회원 계정 및 분리 저장하고 있던 개인정보들이 모두 삭제되며, 더 이상 회원 계정으로 로그인하실 수 없습니다. <br><br>
            ⑥ 회원 탈퇴<br>
            회원의 요청 및 휴면 계정의 탈퇴 상태 전환 시, 개인정보 및 회원의 모든 활동 데이터는 즉시 파기되며 복구 불가합니다.<br><br>
            ⑦ 비회원으로 서비스 이용 시 별도로 수집하는 개인정보<br>
            서비스의 안정성 확보, 안전한 서비스 제공, 법률 및 서비스 이용약관 위반 행위 제한 등의 목적으로 서비스를 이용하는 과정에서 정보가 자동으로 생성 또는 수집될 수 있습니다.<br><br>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('third')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제3조(개인정보의 제3자 제공)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'third' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'third'">
            ① 이용자의 개인정보를 제1조에서 명시한 범위 내에서만 처리하며, 이용자의 동의 및 법률의 특별한 규정 등에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br>
            제공받는 자(입점판매자 [리스트]), 목적(예약 접수확인 및 서비스 이행 등), 제공항목(예약자 이름, 연락처, 이메일), 제공기간(예약일로부터 3년)<br><br>
            ② 다만, 다음의 경우는 예외적으로 법령에 따라 동의없이 개인정보를 제공할 수 있습니다.<br>
            - 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우<br>
            - 정보통신서비스의 제공에 따른 비용 또는 요금 정산을 위하여 필요한 경우<br>
            - 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요함에도 불구하고 동의를 받을 수 없는 경우<br>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('fourth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제4조(개인정보처리의 위탁)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fourth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fourth'">
            웹사이트 및 센터는 원활한 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br><br>
            위탁받는 자(㈜유니드)<br>
            위탁업무(서비스 제공을 위한 운영/개발 및 유지보수, 고객센터 민원 상담업무, 대금 정산 및 처리, 고객 활동 정보 수집 및 분석)<br>
            위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
            위탁받는 자(㈜토스페이먼츠)<br>
            위탁업무(카드 결제, NOL 카드 페이 결제, 법인카드, 카드 비인증 결제, 퀵계좌이체 등을 통한 결제 처리)<br>
            위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
            위탁받는 자(주식회사 카카오페이(효성 ITX))<br>
            위탁업무(카카오페이 간편 결제(카카오페이 고객 상담))<br>
            위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
            위탁받는 자((주)알리는사람들)<br>
            위탁업무(예약 정보의 SMS 발신)<br>
            위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
            위탁받는 자(NICE 평가정보 (주))<br>
            위탁업무(본인확인 서비스)<br>
            위탁기간(해당 업체에서 이미 보유하고 있는 개인정보이기 때문에 별도로 저장하지 않음)<br><br>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('fifth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제5조(정보주체의 권리·의무 및 행사방법)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fifth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fifth'">
            ① 정보주체(이용자)는 낭만남해에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.<br>
            1. 개인정보 및 위치정보 이용·제공사실 확인자료, 개인위치정보 제3자 제공된 이유 및 내용 열람 요구<br>
            2. 오류 등이 있을 경우 정정 요구<br>
            3. 삭제 요구<br>
            4. 처리 정지 요구<br>
            5. 동의 철회 요구<br>
            ② 이용자(정보주체)의 개인정보에 대한 조회, 수정 또는 탈퇴 등의 권리행사는 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 할 수 있습니다. <br>
            1. 정보주체(이용자)가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는  정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. <br>
            2. 제1항에 따른 권리 행사는 정보 주체(이용자)의 법정 대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. <br>
            3. 개인정보 열람 및 처리 정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보 주체(이용자)의 권리가 제한될 수 있습니다. <br>
            4. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. <br>
            5. 낭만남해는 정보주체(이용자) 권리에 따른 열람의 요구, 정정•삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다. <br>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('sixth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제6조(개인정보의 파기)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'sixth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'sixth'">
            ① 낭만남해는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br>
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다. <br>
            ③ 개인정보 파기의 절차 및 방법은 다음과 같이 처리합니다. <br>
            - 파기절차 : 파기 사유가 발생한 개인정보를 선정하고, 웹사이트의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. <br>
            - 파기방법 : 웹사이트는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. <br>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('seventh')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제7조(개인정보의 안전성 확보조치)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'seventh' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'seventh'">
            낭만남해는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br>
            ① 기술적 조치 : 개인정보처리시스템 등 접근권한관리, 주요 개인정보 등 암호화, 보안프로그램 설치 및 가동 등<br>
            ② 물리적 조치 : 전산실, 자료보관실 등의 접근통제 등<br>
            ③ 관리적 조치 : 접근권한 제한, 내부관리계획 수립 및 교육 실시 등<br><br>

            낭만남해는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않으며, 이용자 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디(ID) 와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다.<br>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('eighth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제8조(개인정보 자동수집장치 설치·운영·거부사항)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'eighth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'eighth'">
            ① 낭만남해는 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 이용정보를 저장하는 쿠키(cookie)를 사용합니다. <br>
            1) 쿠키 등 사용 목적 <br>
            이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 이용자에게 편의를 제공하기 위하여 사용합니다.<br>
            2) 쿠키의 설치/운영 <br>
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
            3) 쿠키 설정 거부 방법 <br>
            쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹 브라우저의 옵션을 택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.<br>
            - 엣지(Microsoft Edge) : 설정 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터 관리 및 삭제<br>
            - 크롬(Chrome) : 설정 > 개인정보 및 보안 > 쿠기 및 기타<br>
            - 그 외 브라우저의 경우 브라우저별 설정 방식에 따름<br>
            ② 웹사이트는 이용자의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여정도 파악 등을 통한 타겟 마케팅을 위하여 웹 로그를 수집합니다. <br>
            Google 애널리틱스(Analytics)와 같이 다양한 외부 웹 로그 분석도구를 사용할 수 있으며, Google 애널리틱스의 경우 데이터가 사용되는 것을 거부할 수 있습니다. <br>
            - Google 애널리틱스 차단 : https://tools.google.com/dlpage/gaoptout/ 참조
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('ninth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제9조(App설치에 따른 접근권한)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'ninth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'ninth'">
            IOS/Android의 접근 항목 저장공간(선택), 카메라(선택), 알림(선택)<br>
            저장공간, 카메라 접근 목적 리뷰, 1:1 문의 등록 시, 이미지 등록을 위한 권한<br>
            알림 접근 목적 결제, 마케팅 알림을 위한 권한<br>
            비고 ※ 선택적 접근 권한은 해당 기능을 사용할 때 허용이 필요하며, 허용하지 않아도 해당 기능 외 서비스는 이용 가능
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('tenth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제10조(개인정보보호 책임 및 담당)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'tenth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'tenth'">
            ① 이용자의 개인정보를 처리하는 책임자는 다음과 같습니다. <br>
            - 개인정보 보호 책임자<br>
            * 이름: 송기준<br>
            * 소속: (주) 유니드<br>
            * 전화: 055-761-9543<br>
            * 메일: help@unids.kr<br>
            ② 개인정보가 침해되어 이에 대한 신고나 보다 자세한 상담이 필요한 경우에는 센터와는 별도인 다음의 전문기관에 문의를 통해 도움을 받을 수 있습니다.<br>
            개인정보 침해신고센터 (국번없이) 118 홈페이지(https://privacy.kisa.or.kr)<br>
            개인정보 분쟁조정위원회 (국번없이) 1833-6972 홈페이지(www.kopico.go.kr) <br>
            대검찰청 사이버범죄수사과 (국번없이) 1301 홈페이지(www.spo.go.kr) <br>
            경찰청 사이버 수사국 (국번없이) 182 홈페이지(ecrm.police.go.kr) <br>
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('num11')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제11조 (개인위치정보의 처리)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num11' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'num11'">
            낭만남해는 위치정보의 보호 및 이용 등에 관한 법률에 따라 개인위치정보를 안전하게 관리합니다.<br>
            ① 개인위치정보의 처리 목적 및 보유기간<br>
            1. 낭만남해는 위치정보이용약관 제4조에 따른 서비스 제공을 위하여 개인위치정보를 보유할 수 있으며, 위치기반서비스 이용 및 제공 목적 달성시에는 이를 지체없이 파기합니다.<br>
            2. 낭만남해는 위치기반서비스 제공을 위해 필요한 경우 이용목적 달성을 위해 필요한 최소한의 기간 동안 개인위치정보를 보유할 수 있습니다.<br>
            ② 개인위치정보수집·이용·제공사실 확인자료의 보유근거 및 보유기간<br>
            낭만남해는 개인위치정보수집·이용·제공사실 확인자료를 위치정보의 보호 및 이용 등에 관한 법률 제16조2항에 따라 위치정보시스템에 1년간 보관합니다.<br>
            ③ 개인위치정보의 파기<br>
            낭만남해는 개인위치정보의 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 개인위치정보 처리목적이 달성된 경우, 개인위치정보를 복구 및 재생되지 않도록 안전하게 삭제합니다. 다만, 다른 법령에 따라 보관해야 하는 정당한 사유가 있는 경우에는 그에 따릅니다.<br>
            ④ 개인위치정보의 제3자 제공<br>
            낭만남해는 이용자의 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다. 이때 이용자의 동의를 받아 개인위치정보를 제3자에게 제공하는 경우, 제공할때 마다 제공받는자, 제공일시 및 제공목적을 즉시 통지합니다.<br>
            ③ 낭만남해는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동 등")의 위치정보의 보호 및 이용 등에 관한 법률 제26조 제2항의 규정에 해당하는 자(이하 "보호의무자")가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br>
            1. 8세 이하의 아동<br>
            2. 미성년후견인<br>
            3. 장애인복지법 제2조 제2항 제2호의 규정에 따른 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조 제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다.)<br>
            ④ 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 낭만남해에 제출하여야 합니다. 보호의무자는 8세 이하의 아동 등의 개인위치정보 수집, 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.<br><br>

            그 외 개인위치정보 처리와 관련된 자세한 내용은 위치정보 이용약관을 참고하시기 바랍니다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggleText('num12')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>제12조 (고지의 의무)</p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num12' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num12'">
              ① 본 개인정보처리방침을 포함한 기타 개인정보보호에 대한 상세한 내용은 서비스 홈페이지 첫 화면에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다. <br>
              ② 법령 정책 또는 보안기술의 변경에 따라 중요한 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 서비스 홈페이지를 통해 변경이유 및 내용 등에 대하여 공지하도록 하겠습니다. <br>
              ③ 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 서비스 홈페이지를 방문하실 때마다 이를 확인하시기 바랍니다. <br>
            </div>
          </div>
        </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggleText('num13')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제13조(개인정보처리방침의 변경)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num13' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'num13'">
            낭만남해는 정책 또는 관계 법령의 변경에 따라 본 개인정보처리방침을 변경할 수 있으며, 본 개인정보처리방침은 2024.05.01.부터 적용됩니다.<br>
          </div>
        </div>
    </div>
      </div>
    </div>
    <div class="content-list mb-only">
      <div class="top__content-box">
        남해군에서 운영하는 온라인 관광플랫폼(이하 “낭만남해“라 한다)은 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보처리방침을 수립·공개합니다.
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('first')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제1조 (개인정보 처리목적)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'first'">
          낭만남해는 다음의 목적을 위하여 개인정보를 처리합니다.<br>
          ① 홈페이지 회원가입 및 관리<br>
          웹사이트에서 제공하는 서비스 이용을 위한 목적, 본인확인, 개인식별, 가입의사 확인, 연력 확인, 민원 처리, 고지사항 전달 등<br>
          ② 서비스 제공<br>
          예약결제 등 낭만남해 서비스 제공을 위한 업무 전반에 필요한 정보 제공 등<br>
          ③ 통계 활용<br>
          서비스의 유효성 확인, 접속빈도 파악 또는 서비스 이용에 대한 통계 등
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('second')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제2조(개인정보 수집이용 항목 및 보유기간)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'second' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'second'">
          ① 낭만남해가가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며 수집하는 개인정보의 이용 목적, 항목, 보유기간은 다음과 같습니다.<br><br>
          수집이용목적(회원가입, 계약의 이행 및 서비스 제공, 예약∙구매∙관심상품 내역, 결제대금의 청구, 상담∙불만∙민원처리, 고지∙안내사항 전달, 상품∙서비스 이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천, 불법∙부정이용방지)<br>
          수집이용항목(
          [일반 회원]
          휴대전화번호(ID), 비밀번호, SNS 계정 정보(네이버, 카카오, 애플로 가입 시), 본인확인정보(성명, 휴대전화번호, 통신사, 생년월일, 성별, 내외국인여부) 등<br><br>

          [입점업체]<br>
          아이디, 비밀번호, 이메일, 대표자명, 연락처, 계좌정보, 사업자등록번호, 사업장 주소 등<br>

          [서비스 이용 및 부정이용 기록 확인]<br>
          서비스 이용시간/이용기록, 접속로그, 이용컨텐츠, 접속IP정보, 기기모델명, 브라우저 정보, PC/모바일 환경)<br>

          보유 및 이용기간(- 회원 탈퇴 시 상담 등 민원처리, 정산 및 환불 처리를 위해 30일 후 삭제<br>
          - 관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관 (예: 구매 회원의 경우 5년간, IP의 경우 3개월))<br><br>
          수집이용목적(예약/결제,예약이력 확인)<br>
          수집이용 항목(예약정보(예약자 이름, 휴대전화번호, 이메일), 결제정보 * 결제 시 개인정보는 PG(결제대행업체)사에서 수집 및 저장하고 있으며, 낭만남해는 PG사에서 제공하는 거래 내역만 제공받음)<br>
          보유 및 이용기간(전자상거래 등에서의 소비자 보호에 관한 법률에 따라 5년 보관)<br><br>
          수집이용목적(위치기반서비스 제공)<br>
          수집이용 항목(이용자 위치를 활용한 서비스 제공)<br>
          보유 및 이용기간(일회적 이용 후 즉시 삭제)<br><br>
          수집이용목적(물품(경품)배송)<br>
          수집이용 항목(이름, 주소, 휴대전화번호, 신분증 사본(제세공과금 처리 시))<br>
          보유 및 이용기간(물품(경품) 배송 완료 시까지)<br><br>
          ② 수집방법
          이용자들에 대한 서비스 제공을 위한 필수적인 정보들을 온라인 상에서 입력 받고 있습니다. 그러나, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시 구할 것입니다.<br><br>
          ③ 다음의 경우는 예외적으로 법령에 따라 동의없이 개인정보를 수집·이용할 수 있습니다. <br>
            - 정보통신서비스의 제공에 관한 계약을 이행을 위하여 필요한 개인정보로서 경제적·기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우<br>
            - 정보통신서비스의 제공에 따른 비용 또는 요금 정산을 위하여 필요한 경우<br>
            - 수집목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않는 경우<br>
            - 그 밖에 법률에 특별한 규정이 있는 경우<br><br>
          ④ 관련법령에 의한 정보보유 사유 및 의무<br>
          계약 또는 청약철회 등에 관한 기록(5년) 전자상거래등에서의 소비자보호에 관한 법률<br>
          대금결제 및 재화 등의 공급에 관한 기록(5년) 전자상거래 등에서의 소비자보호에 관한 법률<br>
          소비자의 불만 또는 분쟁처리에 관한 기록(5년) 전자상거래 등에서의 소비자보호에 관한 법률<br>
          웹사이트 방문 기록(3개월) 통신비밀보호법<br>
          전자금융거래에 관한 기록(5년) 전자금융거래법<br>
          고객확인 및 의심거래보고 등에 관한 기록(5년) 특정 금융거래정보의 보고 및 이용 등에 관한 법률<br><br>
          ⑤ 개인정보 유효기간제(휴면 계정 정책)<br>
            1) 1년간 서비스 이용 기록이 없는 정보주체(이용자)의 회원가입 정보는 개인정보 보호법 제39조 6에 따라 일반정보 주체(이용자)의 회원가입 정보와 분리하여 별도로 저장 및 관리됩니다. (휴면 계정으로 전환)<br>
            2) 다른 법령에서 별도의 기간을 정하고 있거나 정보 주체(이용자)의 요청이 있는 경우를 제외하면, 법령에서 정의하는 기간 동안 재이용하지 아니하는 회원의 개인정보를 파기하거나 다른 회원의 개인정보와 분리하여 별도로 저장·관리합니다. <br>
            3) 기간 만료 30일 전 개인정보가 파기되거나 분리되어 저장·관리되는 사실을 문자, 이메일 또는 이와 유사한 방법으로 회원에게 알립니다. <br>
            4) 휴면 계정으로 전환된 후 5년이 지나도 서비스를 이용하지 않으면 회원 계정 및 분리 저장하고 있던 개인정보들이 모두 삭제되며, 더 이상 회원 계정으로 로그인하실 수 없습니다. <br><br>
          ⑥ 회원 탈퇴<br>
          회원의 요청 및 휴면 계정의 탈퇴 상태 전환 시, 개인정보 및 회원의 모든 활동 데이터는 즉시 파기되며 복구 불가합니다.<br><br>
          ⑦ 비회원으로 서비스 이용 시 별도로 수집하는 개인정보<br>
          서비스의 안정성 확보, 안전한 서비스 제공, 법률 및 서비스 이용약관 위반 행위 제한 등의 목적으로 서비스를 이용하는 과정에서 정보가 자동으로 생성 또는 수집될 수 있습니다.<br><br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('third')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제3조(개인정보의 제3자 제공)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'third' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'third'">
          ① 이용자의 개인정보를 제1조에서 명시한 범위 내에서만 처리하며, 이용자의 동의 및 법률의 특별한 규정 등에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br>
          제공받는 자(입점판매자 [리스트]), 목적(예약 접수확인 및 서비스 이행 등), 제공항목(예약자 이름, 연락처, 이메일), 제공기간(예약일로부터 3년)<br><br>
          ② 다만, 다음의 경우는 예외적으로 법령에 따라 동의없이 개인정보를 제공할 수 있습니다.<br>
          - 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우<br>
          - 정보통신서비스의 제공에 따른 비용 또는 요금 정산을 위하여 필요한 경우<br>
          - 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요함에도 불구하고 동의를 받을 수 없는 경우<br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('fourth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제4조(개인정보처리의 위탁)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fourth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'fourth'">
          웹사이트 및 센터는 원활한 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br><br>
          위탁받는 자(㈜유니드)<br>
          위탁업무(서비스 제공을 위한 운영/개발 및 유지보수, 고객센터 민원 상담업무, 대금 정산 및 처리, 고객 활동 정보 수집 및 분석)<br>
          위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
          위탁받는 자(㈜토스페이먼츠)<br>
          위탁업무(카드 결제, NOL 카드 페이 결제, 법인카드, 카드 비인증 결제, 퀵계좌이체 등을 통한 결제 처리)<br>
          위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
          위탁받는 자(주식회사 카카오페이(효성 ITX))<br>
          위탁업무(카카오페이 간편 결제(카카오페이 고객 상담))<br>
          위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
          위탁받는 자((주)알리는사람들)<br>
          위탁업무(예약 정보의 SMS 발신)<br>
          위탁기간(회원탈퇴 또는 위탁계약 종료시까지)<br><br>
          위탁받는 자(NICE 평가정보 (주))<br>
          위탁업무(본인확인 서비스)<br>
          위탁기간(해당 업체에서 이미 보유하고 있는 개인정보이기 때문에 별도로 저장하지 않음)<br><br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('fifth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제5조(정보주체의 권리·의무 및 행사방법)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fifth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'fifth'">
          ① 정보주체(이용자)는 낭만남해에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.<br>
          1. 개인정보 및 위치정보 이용·제공사실 확인자료, 개인위치정보 제3자 제공된 이유 및 내용 열람 요구<br>
          2. 오류 등이 있을 경우 정정 요구<br>
          3. 삭제 요구<br>
          4. 처리 정지 요구<br>
          5. 동의 철회 요구<br>
          ② 이용자(정보주체)의 개인정보에 대한 조회, 수정 또는 탈퇴 등의 권리행사는 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 할 수 있습니다. <br>
          1. 정보주체(이용자)가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는  정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. <br>
          2. 제1항에 따른 권리 행사는 정보 주체(이용자)의 법정 대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. <br>
          3. 개인정보 열람 및 처리 정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보 주체(이용자)의 권리가 제한될 수 있습니다. <br>
          4. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. <br>
          5. 낭만남해는 정보주체(이용자) 권리에 따른 열람의 요구, 정정•삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다. <br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('sixth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제6조(개인정보의 파기)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'sixth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'sixth'">
          ① 낭만남해는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br>
          ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다. <br>
          ③ 개인정보 파기의 절차 및 방법은 다음과 같이 처리합니다. <br>
          - 파기절차 : 파기 사유가 발생한 개인정보를 선정하고, 웹사이트의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. <br>
          - 파기방법 : 웹사이트는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. <br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('seventh')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제7조(개인정보의 안전성 확보조치)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'seventh' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'seventh'">
          낭만남해는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br>
          ① 기술적 조치 : 개인정보처리시스템 등 접근권한관리, 주요 개인정보 등 암호화, 보안프로그램 설치 및 가동 등<br>
          ② 물리적 조치 : 전산실, 자료보관실 등의 접근통제 등<br>
          ③ 관리적 조치 : 접근권한 제한, 내부관리계획 수립 및 교육 실시 등<br><br>

          낭만남해는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않으며, 이용자 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디(ID) 와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다.<br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('eighth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제8조(개인정보 자동수집장치 설치·운영·거부사항)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'eighth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'eighth'">
          ① 낭만남해는 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 이용정보를 저장하는 쿠키(cookie)를 사용합니다. <br>
          1) 쿠키 등 사용 목적 <br>
          이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 이용자에게 편의를 제공하기 위하여 사용합니다.<br>
          2) 쿠키의 설치/운영 <br>
          이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
          3) 쿠키 설정 거부 방법 <br>
          쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹 브라우저의 옵션을 택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.<br>
          - 엣지(Microsoft Edge) : 설정 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터 관리 및 삭제<br>
          - 크롬(Chrome) : 설정 > 개인정보 및 보안 > 쿠기 및 기타<br>
          - 그 외 브라우저의 경우 브라우저별 설정 방식에 따름<br>
          ② 웹사이트는 이용자의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여정도 파악 등을 통한 타겟 마케팅을 위하여 웹 로그를 수집합니다. <br>
          Google 애널리틱스(Analytics)와 같이 다양한 외부 웹 로그 분석도구를 사용할 수 있으며, Google 애널리틱스의 경우 데이터가 사용되는 것을 거부할 수 있습니다. <br>
          - Google 애널리틱스 차단 : https://tools.google.com/dlpage/gaoptout/ 참조
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('ninth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제9조(App설치에 따른 접근권한)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'ninth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'ninth'">
          IOS/Android의 접근 항목 저장공간(선택), 카메라(선택), 알림(선택)<br>
          저장공간, 카메라 접근 목적 리뷰, 1:1 문의 등록 시, 이미지 등록을 위한 권한<br>
          알림 접근 목적 결제, 마케팅 알림을 위한 권한<br>
          비고 ※ 선택적 접근 권한은 해당 기능을 사용할 때 허용이 필요하며, 허용하지 않아도 해당 기능 외 서비스는 이용 가능
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('tenth')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제10조(개인정보보호 책임 및 담당)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'tenth' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'tenth'">
          ① 이용자의 개인정보를 처리하는 책임자는 다음과 같습니다. <br>
          - 개인정보 보호 책임자<br>
          * 이름: 송기준<br>
          * 소속: (주) 유니드<br>
          * 전화: 055-761-9543<br>
          * 메일: help@unids.kr<br>
          ② 개인정보가 침해되어 이에 대한 신고나 보다 자세한 상담이 필요한 경우에는 센터와는 별도인 다음의 전문기관에 문의를 통해 도움을 받을 수 있습니다.<br>
          개인정보 침해신고센터 (국번없이) 118 홈페이지(https://privacy.kisa.or.kr)<br>
          개인정보 분쟁조정위원회 (국번없이) 1833-6972 홈페이지(www.kopico.go.kr) <br>
          대검찰청 사이버범죄수사과 (국번없이) 1301 홈페이지(www.spo.go.kr) <br>
          경찰청 사이버 수사국 (국번없이) 182 홈페이지(ecrm.police.go.kr) <br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('num11')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제11조 (개인위치정보의 처리)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num11' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'num11'">
          낭만남해는 위치정보의 보호 및 이용 등에 관한 법률에 따라 개인위치정보를 안전하게 관리합니다.<br>
          ① 개인위치정보의 처리 목적 및 보유기간<br>
          1. 낭만남해는 위치정보이용약관 제4조에 따른 서비스 제공을 위하여 개인위치정보를 보유할 수 있으며, 위치기반서비스 이용 및 제공 목적 달성시에는 이를 지체없이 파기합니다.<br>
          2. 낭만남해는 위치기반서비스 제공을 위해 필요한 경우 이용목적 달성을 위해 필요한 최소한의 기간 동안 개인위치정보를 보유할 수 있습니다.<br>
          ② 개인위치정보수집·이용·제공사실 확인자료의 보유근거 및 보유기간<br>
          낭만남해는 개인위치정보수집·이용·제공사실 확인자료를 위치정보의 보호 및 이용 등에 관한 법률 제16조2항에 따라 위치정보시스템에 1년간 보관합니다.<br>
          ③ 개인위치정보의 파기<br>
          낭만남해는 개인위치정보의 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 개인위치정보 처리목적이 달성된 경우, 개인위치정보를 복구 및 재생되지 않도록 안전하게 삭제합니다. 다만, 다른 법령에 따라 보관해야 하는 정당한 사유가 있는 경우에는 그에 따릅니다.<br>
          ④ 개인위치정보의 제3자 제공<br>
          낭만남해는 이용자의 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다. 이때 이용자의 동의를 받아 개인위치정보를 제3자에게 제공하는 경우, 제공할때 마다 제공받는자, 제공일시 및 제공목적을 즉시 통지합니다.<br>
          ③ 낭만남해는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동 등")의 위치정보의 보호 및 이용 등에 관한 법률 제26조 제2항의 규정에 해당하는 자(이하 "보호의무자")가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br>
          1. 8세 이하의 아동<br>
          2. 미성년후견인<br>
          3. 장애인복지법 제2조 제2항 제2호의 규정에 따른 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조 제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다.)<br>
          ④ 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 낭만남해에 제출하여야 합니다. 보호의무자는 8세 이하의 아동 등의 개인위치정보 수집, 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.<br><br>

          그 외 개인위치정보 처리와 관련된 자세한 내용은 위치정보 이용약관을 참고하시기 바랍니다.<br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('num12')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제12조 (고지의 의무)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num12' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'num12'">
          ① 본 개인정보처리방침을 포함한 기타 개인정보보호에 대한 상세한 내용은 서비스 홈페이지 첫 화면에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다. <br>
          ② 법령 정책 또는 보안기술의 변경에 따라 중요한 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 서비스 홈페이지를 통해 변경이유 및 내용 등에 대하여 공지하도록 하겠습니다. <br>
          ③ 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 서비스 홈페이지를 방문하실 때마다 이를 확인하시기 바랍니다. <br>
        </div>
      </div>
      <div class="content-box">
        <div class="title" @click="toggleText('num13')">
          <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
          <p>제13조(개인정보처리방침의 변경)</p>
          <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num13' }]"></i>
        </div>
        <div class="text" v-show="showtext === 'num13'">
          낭만남해는 정책 또는 관계 법령의 변경에 따라 본 개인정보처리방침을 변경할 수 있으며, 본 개인정보처리방침은 2024.05.01.부터 적용됩니다.<br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showtext: false,
    };
  },
  methods: {
    toggleText(text) {
      this.showtext = (this.showtext === text) ? null : text;
    },
  },
};
</script>
